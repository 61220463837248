import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/shared/Layout";
import SEO from "../../components/SEO/SEO";
import Breadcrumbs from "../../components/shared/Breadcrumbs";
import PostTile from "../../components/shared/PostTile";
import "../../assets/styles/pages/category.scss";

const Tag = (props) => {

  const {
    data: {
      allWpPost: { nodes },
      wpTag: { name, seo},
    },
    pageContext: { uri, perPage },
  } = props;

  const createGroups = (pages, pageLength) =>
    pages.map((edge, index) => {
      return index % pageLength === 0
        ? pages.slice(index, index + pageLength)
        : null;
    }).filter(item => item);
  const groups = createGroups(nodes, perPage);
  const [items, setItems] = useState(groups[0]);
  const [pageIndex, setPageIndex] = useState(1);
  const loadMore = () => {
    setPageIndex(pageIndex + 1);
    setItems(items.concat(groups[pageIndex]));
  };

  const metaTitle = name + " - Archives - SCR888/ 918kiss";
  const metaDesc = "กรุณาตรวจสอบรายการโพสต์ทั้งหมดด้วยแท้ก: " + name + " แฟ้มข้อมูล - SCR888 / 918kiss";

  return (
    <Layout>
      <SEO title={metaTitle} description={metaDesc} uri={uri}/>
      <Breadcrumbs breadcrumbs={seo.breadcrumbs} pageUrl={uri} pageTitle={seo.title}/>
      <div className={"wrapper"}>
        <h1>Tag: {name}</h1>
        <ul className={"category-list"}>
          {items && items.map((post, index) => {
            return (
              <PostTile key={index} post={post}/>
            );
          })}
        </ul>
      </div>
      {(groups.length > 1 && pageIndex <= groups.length - 1) &&
      <div className="loadmore-wrap">
        <button className="button"
                onClick={() => loadMore()}>
          <span>โหลดมากขึ้น...</span>
        </button>
      </div>
      }

    </Layout>
  );
};

export const query = graphql`
  query TagPage(
    $perPage: Int!
    $userDatabaseId: Int
    $tagDatabaseId: Int
  ) {
    wpTag(databaseId: {eq: $tagDatabaseId}) {
      name
      databaseId
      seo {
        breadcrumbs {
          text
          url
        }
        metaDesc
        title
        metaKeywords
      }
    }
    allWpPost(
      limit: $perPage
      filter: {
        author: { node: { databaseId: { eq: $userDatabaseId } } }
        tags: {
          nodes: { elemMatch: { databaseId: { eq: $tagDatabaseId } } }
        }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        ...PostPreviewContent
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
    }
  }
`;

export default Tag;
